/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CollectionReference,
  DocumentData,
  QueryConstraint,
  collection,
  getFirestore,
  orderBy,
} from 'firebase/firestore'

import {
  BaseArticle,
  BaseCoupon,
  BaseDeckArticle,
  Cookie,
  Currency,
  IComment,
  Treasure,
} from '@/types/ckk'
import { BaseCktArticle } from '@/types/ckt/article'
import { BaseCktArtifact } from '@/types/ckt/artifact'
import { BaseCktCookieTier, BaseCktCookie } from '@/types/ckt/cookie'
import { BaseCktCoupon } from '@/types/ckt/coupon'
import { CktCurrency } from '@/types/ckt/currency'
import { BaseParty } from '@/types/ckt/party'
import { CktRaid } from '@/types/ckt/raid'
import { BaseCktStage } from '@/types/ckt/stage'
import { UserNotification } from '@/types/notification'
import { Profile } from '@/types/user'
import { removeNestedUndefinedOrEmpty } from '@/utils/object'
import { gradeSorter } from '@/utils/sort'
import { sendTelegramMessage } from '@/utils/telegram'
import { getFirebaseApp } from '@/utils/webpush'

const app = getFirebaseApp()
const db = getFirestore(app)

function getCollection(collectionName: string) {
  return collection(db, collectionName)
}

const readCollectionDocs =
  <T>({
    collectionRef,
    queryConstraints = [orderBy('createdAt', 'desc')],
    sorter,
  }: {
    collectionRef: CollectionReference<DocumentData>
    queryConstraints?: QueryConstraint[]
    sorter?: (a: T, b: T) => number
  }) =>
  async (
    {
      withNotification,
      limit: limitCount,
      where: whereParam,
      startAfter: startAfterParam,
      orderBy: orderByParam,
    }: {
      withNotification?: boolean
      limit?: number
      orderBy?: { [key: string]: 'asc' | 'desc' }
      where?: { [key: string]: any }
      startAfter?: { [key: string]: any }
    } = { withNotification: false },
  ): Promise<T[]> => {
    const { getDocs, query, where, limit, startAfter } = await import(
      'firebase/firestore'
    )

    const nextQueryConstraints = [
      ...(whereParam
        ? Object.entries(whereParam)
            .filter(([_, value]) => !!value)
            .map(([key, value]) => where(key, '==', value))
        : []),
      ...(orderByParam
        ? Object.entries(orderByParam).map(([key, value]) =>
            orderBy(key, value),
          )
        : []),
      ...(orderByParam || startAfterParam ? [] : queryConstraints),
      ...(startAfterParam
        ? Object.entries(startAfterParam).map(([key, value]) => [
            orderBy(key, 'desc'),
            startAfter(value),
          ])
        : []
      ).flat(),
      ...(limit ? [limit(limitCount)] : []),
    ]

    const q = query(collectionRef, ...nextQueryConstraints)

    const querySnapshot = await getDocs(q)

    const docs = querySnapshot.docs.map((_doc) => ({
      id: _doc.id,
      ..._doc.data(),
    })) as T[]

    if (withNotification) {
      await sendTelegramMessage({
        topic: `readCollectionDocs / ${collectionRef.path}`,
        message: ``,
      })
    }

    return docs.sort(sorter)
  }

const readCollectionDocById =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    id,
    withNotification = false,
  }: {
    id: string
    withNotification?: boolean
  }): Promise<T | null> => {
    try {
      const { doc, getDoc } = await import('firebase/firestore')
      const docRef = doc(collectionRef, id)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        const _doc = { id: docSnap.id, ...docSnap.data() } as T

        if (withNotification) {
          await sendTelegramMessage({
            topic: `readDocById / ${collectionRef.path}`,
            message: ``,
          })
        }

        return _doc
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

const createCollectionDoc =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    doc: _doc,
    withNotification = false,
  }: {
    doc: Omit<T, 'id'>
    withNotification?: boolean
  }): Promise<string> => {
    const { addDoc } = await import('firebase/firestore')
    const { id } = await addDoc(
      collectionRef,
      removeNestedUndefinedOrEmpty(_doc),
    )

    if (withNotification) {
      await sendTelegramMessage({
        topic: `createCollectionDoc / ${collectionRef.path}`,
        message: ['생성 정보(JSON)', JSON.stringify(_doc, null, 4)].join('\n'),
      })
    }

    return id
  }

export const updateCollectionDoc =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    id,
    doc: _doc,
    withNotification = false,
  }: {
    id: string
    doc: Omit<T, 'id'>
    withNotification?: boolean
  }): Promise<string> => {
    const { doc, updateDoc } = await import('firebase/firestore')
    const docRef = doc(collectionRef, id)
    await updateDoc(docRef, removeNestedUndefinedOrEmpty(_doc))

    if (withNotification) {
      await sendTelegramMessage({
        topic: `updateDoc / ${collectionRef.path}`,
        message: ['수정 정보(JSON)', JSON.stringify(_doc, null, 4)].join('\n'),
      })
    }

    return id
  }

export const deleteCollectionDoc =
  ({ collectionRef }: { collectionRef: CollectionReference<DocumentData> }) =>
  async ({
    id,
    withNotification = false,
  }: {
    id: string
    withNotification?: boolean
  }): Promise<string> => {
    const { doc, deleteDoc } = await import('firebase/firestore')
    const docRef = doc(collectionRef, id)
    await deleteDoc(docRef)

    if (withNotification) {
      await sendTelegramMessage({
        topic: `deleteDoc / ${collectionRef.path}`,
        message: `id: ${id}}`,
      })
    }

    return id
  }

export const readDocByEnName =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    enName,
    withNotification = false,
  }: {
    enName: string
    withNotification?: boolean
  }) => {
    const { query, where, limit, getDocs } = await import('firebase/firestore')
    const docs: T[] = []
    const q = query(collectionRef, where('name.en', '==', enName), limit(1))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((_doc) => {
      const doc = { id: _doc.id, ..._doc.data() } as T
      docs.push(doc)
    })

    const doc = docs?.[0] ?? null

    if (withNotification) {
      await sendTelegramMessage({
        topic: '단일 문서 조회 / readDocByEnName',
        message: `조회 Query : ${JSON.stringify({ enName })}
조회 결과(json)
${JSON.stringify(docs, null, 4)}`,
      })
    }
    return doc
  }

function getCollectionActions<T>({
  collectionName,
  sorter,
}: {
  collectionName: string
  sorter?: (a: T, b: T) => number
}) {
  const collectionRef = getCollection(collectionName)

  return {
    readDocs: readCollectionDocs<T>({
      collectionRef,
      sorter,
    }),
    readDocById: readCollectionDocById<T>({
      collectionRef,
    }),
    createDoc: createCollectionDoc<T>({
      collectionRef,
    }),
    updateDoc: updateCollectionDoc<Partial<T>>({
      collectionRef,
    }),
    deleteDoc: deleteCollectionDoc({
      collectionRef,
    }),
    readDocByEnName: readDocByEnName<T>({
      collectionRef,
    }),
  }
}

const firestoreAction = {
  cktCookies: getCollectionActions<BaseCktCookie>({
    collectionName: 'cktCookies',
  }),
  cktCoupons: getCollectionActions<BaseCktCoupon>({
    collectionName: 'cktCoupons',
  }),
  cktCurrencies: getCollectionActions<CktCurrency>({
    collectionName: 'cktCurrencies',
  }),
  cktCookieTiers: getCollectionActions<BaseCktCookieTier>({
    collectionName: 'cktCookieTiers',
  }),
  cktRaids: getCollectionActions<CktRaid>({
    collectionName: 'cktRaids',
  }),
  cktStages: getCollectionActions<BaseCktStage>({
    collectionName: 'cktStages',
    sorter: (a, b) => a.chapter - b.chapter || a.stage - b.stage,
  }),
  cktParties: getCollectionActions<BaseParty>({
    collectionName: 'cktParties',
  }),
  comments: getCollectionActions<IComment>({
    collectionName: 'comments',
  }),
  userNotifications: getCollectionActions<UserNotification>({
    collectionName: 'userNotifications',
  }),
  cktArtifacts: getCollectionActions<BaseCktArtifact>({
    collectionName: 'cktArtifacts',
    sorter: gradeSorter,
  }),
  cktArticles: getCollectionActions<BaseCktArticle>({
    collectionName: 'cktArticles',
  }),
  coupons: getCollectionActions<BaseCoupon>({
    collectionName: 'coupons',
  }),
  currencies: getCollectionActions<Currency>({
    collectionName: 'currencies',
  }),
  cookies: getCollectionActions<Cookie>({
    collectionName: 'cookies',
  }),
  treasures: getCollectionActions<Treasure>({
    collectionName: 'treasures',
  }),
  articles: getCollectionActions<BaseArticle>({
    collectionName: 'articles',
  }),
  decks: getCollectionActions<BaseDeckArticle>({
    collectionName: 'decks',
  }),
  profiles: getCollectionActions<Profile>({
    collectionName: 'profiles',
  }),
}

export default firestoreAction
