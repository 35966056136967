import { faUser, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type User } from 'firebase/auth';
import { TFunction } from 'i18next';
import { type MenuConfig, type SingleMenu } from '@/types/shared';
export const COMMON_MENUS = ({
  user,
  t
}: {
  user: User;
  t: TFunction;
}): MenuConfig => [...(user ? [{
  title: t('pages.profile.title'),
  icon: <FontAwesomeIcon className="mr-2" icon={faUser} />,
  pathname: '/account/profile',
  hasSubMenu: false
}] as SingleMenu[] : [{
  title: t('register.title'),
  icon: <FontAwesomeIcon className="mr-2" icon={faSignIn} />,
  pathname: '/account/register',
  hasSubMenu: false
}, {
  title: t('login.title'),
  icon: <FontAwesomeIcon className="mr-2" icon={faUser} />,
  pathname: '/account/login',
  hasSubMenu: false
}] as SingleMenu[])];