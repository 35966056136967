import { useRouter } from 'next/router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect, useRef } from 'react';
import { Col } from 'antd';
interface Props {
  style?: React.CSSProperties;
  index?: 0 | 1 | 2;
  className?: string;
}
function MiddleContentAd({
  style = {},
  index = 0,
  className
}: Props) {
  const router = useRouter();
  const adsLoaded = useRef(false);
  useEffect(() => {
    const loadAd = () => {
      if (typeof window !== 'undefined' && window.adsbygoogle) {
        try {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
          adsLoaded.current = true;
        } catch (error) {
          // sendTelegramMessage({
          //   topic: `클라이언트 에러 - MiddleContentAd`,
          //   message: JSON.stringify(error, null, 4),
          // })
        }
      }
    };
    if (router.query && !adsLoaded.current) {
      setTimeout(loadAd, 0);
    }
  }, [router.query]);
  const slotId = index === 0 ? '3194325153' : index === 1 ? '2306584238' : '8687379503';
  style.display = 'block';
  return <Col key={router.asPath.replace(/\//g, '-') + '-' + slotId + '-' + index} className={`${className}`} xs={24} data-sentry-element="Col" data-sentry-component="MiddleContentAd" data-sentry-source-file="MiddleContentAd.tsx">
      <div className="adsense-horizontal" style={{
      background: '#f9f9f9',
      ...style
    }}>
        {/* <Adsense
         client={process.env.NEXT_PUBLIC_ADSENSE_CLIENT}
         format=""
         slot={slotId}
         style={{ display: 'block' }}
         /> */}
        <ins className="adsbygoogle adsense-horizontal" data-ad-client={process.env.NEXT_PUBLIC_ADSENSE_CLIENT} data-ad-slot={slotId} style={{
        display: 'block'
      }}
      // 아래 값을 넣을 경우 가로 뿐만 아니라 상하로도 광고가 커져서 사용
      // data-full-width-responsive="true"
      />
      </div>
    </Col>;
}
export default MiddleContentAd;