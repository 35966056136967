import dayjs from 'dayjs'

import { GRADE_VALUES, Grade } from '@/types/ckk'

/** 등급으로 내림차순 정렬 */
export function gradeSorter<T extends { grade: Grade }>(a: T, b: T) {
  const aGrade = GRADE_VALUES[a.grade]
  const bGrade = GRADE_VALUES[b.grade]

  return bGrade - aGrade
}

/**
 * 출시일으로 정렬
 * * asc: 오름차순
 * * desc: 내림차순
 */
export const releaseDatetimeSorter =
  <T extends { releaseDatetime: string }>(
    directionStr: 'asc' | 'desc' = 'asc',
  ) =>
  (a: T, b: T): number => {
    return (
      (directionStr === 'asc' ? 1 : -1) *
      (new Date(a.releaseDatetime).getTime() -
        new Date(b.releaseDatetime).getTime())
    )
  }

/** 날짜로 내림차순 정렬 */
export function datetimeSorter<T extends { datetime: number | string | Date }>(
  a: T,
  b: T,
): number {
  return dayjs(b.datetime).isAfter(dayjs(a.datetime)) ? 1 : -1
}

export const startDateSorter =
  <T extends { startDate: string }>(directionStr: 'asc' | 'desc' = 'asc') =>
  (a: T, b: T): number => {
    return (
      (directionStr === 'asc' ? 1 : -1) *
      (new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
    )
  }
