import { ReactNode } from 'react'

import { ArticleCategory } from '@/components/ckk/WriteBoard'
import { DeckArticle } from '@/types/ckk/deck'
import { PostType as CommunityPostType } from '@/types/community'
import { FirestoreCommon } from '@/types/firebase'
import { CouponCommon } from '@/types/shared'
import { Profile } from '@/types/user'

export enum Locale {
  /** 한국어 */
  KO = 'ko',
  /** 영어 */
  EN = 'en',
  /** 일본어 */
  JP = 'jp',
  /** 대만어 */
  TW = 'tw',
  /** 태국어 */
  TH = 'th',
  /** 독일어 */
  DE = 'de',
  /** 프랑스어 */
  FR = 'fr',
}

/** 대명사 */
export enum Pronouns {
  /** 남자 */
  HE = 'he',
  /** 여자 */
  SHE = 'she',
  /** 그들 */
  THEY = 'they',
  /** 그것 */
  IT = 'it',
  /** 없음 */
  NO = 'no',
}

export interface LocalizedString {
  ko: string
  en: string
}

export interface LocalizedReactNode {
  ko: ReactNode
  en: ReactNode
}

export interface BaseObject {
  name: LocalizedString
}

export interface ImageObject extends BaseObject {
  image: string
}

export interface ExtendedImageObject extends BaseObject {
  image: string
}

/** 스킬 */
export interface Skill {
  /** 이름 */
  name: LocalizedString
  /** 쿨타임 */
  initialCooldown?: number
  /** 쿨타임 */
  cooldown: number
  image: string
  /** 게임 설명 */
  description?: {
    ko: string
    en: string
  }
  /** 정보 */
  info?: {
    ko: string
    en: string
  }
  animation?: string
}

/** 등급 */
export enum Grade {
  /** 비스트 */
  BEAST = 'BEAST',
  /** 에인션트 */
  ANCIENT = 'ANCIENT',
  /** 용 */
  DRAGON = 'DRAGON',
  /** 레전더리 */
  LEGENDARY = 'LEGENDARY',
  /** 슈퍼 에픽 */
  SUPER_EPIC = 'SUPER_EPIC',
  /** 스페셜 */
  SPECIAL = 'SPECIAL',
  /** 에픽 */
  EPIC = 'EPIC',
  /** 레어 */
  RARE = 'RARE',
  /** 커먼 */
  COMMON = 'COMMON',
  /** 게스트 */
  GUEST = 'GUEST',
}

export type GradeWithAll = Grade | 'ALL'

export const GRADE_VALUES: Record<CookieGrade, number> = {
  BEAST: 8,
  ANCIENT: 7,
  DRAGON: 6,
  LEGENDARY: 5,
  SUPER_EPIC: 4,
  SPECIAL: 3,
  EPIC: 2,
  RARE: 1,
  COMMON: 0,
  GUEST: -1,
}

export const 뽑기힘든쿠키_등급 = [
  Grade.ANCIENT,
  Grade.DRAGON,
  Grade.LEGENDARY,
  Grade.SUPER_EPIC,
]

export type GachaCookieGrade =
  | Grade.BEAST
  | Grade.ANCIENT
  | Grade.LEGENDARY
  | Grade.DRAGON
  | Grade.SUPER_EPIC
  | Grade.EPIC
  | Grade.RARE
  | Grade.COMMON

export type CookieGrade = Grade

/** 코스튬의 등급 */
export type CostumeGrade =
  | Grade.LEGENDARY
  | Grade.SPECIAL
  | Grade.EPIC
  | Grade.RARE
  | Grade.COMMON

export const COSTUME_GRADE_MAP: Record<CostumeGrade, ExtendedImageObject> = {
  [Grade.LEGENDARY]: {
    name: { ko: '레전더리', en: 'Legendary' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/26746770-e8cc-467e-8d7e-6952e09bb600/public',
  },
  [Grade.EPIC]: {
    name: { ko: '에픽', en: 'Epic' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/c6c49141-89ec-48c4-0554-dc11ae27d900/public',
  },
  [Grade.RARE]: {
    name: { ko: '레어', en: 'Rare' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/66366920-620d-4b46-dc79-d0bea30c7d00/public',
  },
  [Grade.COMMON]: {
    name: { ko: '커먼', en: 'Common' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/54ed426e-eb75-43cd-223e-8bf913047400/public',
  },
  [Grade.SPECIAL]: {
    name: { ko: '스페셜', en: 'Special' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/0a003e6b-91b7-43f7-c83b-2c323fd77400/public',
  },
}

export enum ElementEnum {
  /** 불 속성 */
  FIRE = 'FIRE',
  /** 독 속성 */
  POISON = 'POISON',
  /** 빛 속성 */
  LIGHT = 'LIGHT',
  /** 물 속성 */
  WATER = 'WATER',
  /** 땅 속성 */
  EARTH = 'EARTH',
  /** 얼음 속성 */
  ICE = 'ICE',
  /** 전기 속성 */
  ELECTRICITY = 'ELECTRICITY',
  /** 어둠 속성 */
  DARKNESS = 'DARKNESS',
  /** 바람 속성 */
  WIND = 'WIND',
}

export type ElementEnumWithAll = ElementEnum | 'ALL'

export const ELEMENT_MAP: Record<ElementEnum, ElementType> = {
  [ElementEnum.FIRE]: {
    name: { ko: '불 속성', en: 'Fire Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/08e5d25a-b40e-4603-bab8-8cab7ea5e400/publicIcon',
    element: ElementEnum.FIRE,
  },
  [ElementEnum.POISON]: {
    name: { ko: '독 속성', en: 'Poison Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/f20cb1bc-0d08-493c-f7e0-3742051f9400/publicIcon',
    element: ElementEnum.POISON,
  },
  [ElementEnum.LIGHT]: {
    name: { ko: '빛 속성', en: 'Light Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/8a84cdb6-894e-4582-6871-848e23ac5e00/publicIcon',
    element: ElementEnum.LIGHT,
  },
  [ElementEnum.WATER]: {
    name: { ko: '물 속성', en: 'Water Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/406a3db4-192e-4f02-d930-ac644734d600/publicIcon',
    element: ElementEnum.WATER,
  },
  [ElementEnum.EARTH]: {
    name: { ko: '땅 속성', en: 'Earth Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/a49aa61e-8885-4a2a-1db9-7ed160f52a00/publicIcon',
    element: ElementEnum.EARTH,
  },
  [ElementEnum.ICE]: {
    name: { ko: '얼음 속성', en: 'Ice Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/665368fb-fe85-41b5-d58e-9f8833513700/publicIcon',
    element: ElementEnum.ICE,
  },
  [ElementEnum.ELECTRICITY]: {
    name: { ko: '전기 속성', en: 'Electricity Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/646f65ba-d2de-41af-eed8-f899e8e3e500/publicIcon',
    element: ElementEnum.ELECTRICITY,
  },
  [ElementEnum.DARKNESS]: {
    name: { ko: '어둠 속성', en: 'Darkness Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/759a9084-c4fb-4bda-1673-84dc7464a100/publicIcon',
    element: ElementEnum.DARKNESS,
  },
  [ElementEnum.WIND]: {
    name: { ko: '바람 속성', en: 'Wind Element' },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/8f581c6b-ec42-4b8d-1379-d54976c6dd00/publicIcon',
    element: ElementEnum.WIND,
  },
}

/** Element가 예약어여서 어쩔 수없이 Type suffix 사용 */
export interface ElementType {
  image: string
  name: LocalizedString
  element: ElementEnum
}

export const ELEMENTS: ElementType[] = Object.entries(ELEMENT_MAP).map(
  ([key, value]) => ({
    element: key as ElementEnum,
    ...value,
  }),
)

export enum Type {
  /** 쿠키 */
  COOKIE = 'COOKIE',
  /** 쿠키 영혼석 */
  COOKIE_STONE = 'COOKIE_STONE',
  /** 보물 */
  TREASURE = 'TREASURE',
}

export enum CurrencyType {
  /** 가속권 */
  SPEED_UP = 'SPEED_UP',
  /** 영혼의 조각 */
  SOUL_ESSENCE = 'SOUL_ESSENCE',
  /** 이벤트 */
  EVENT = 'EVENT',
  /** 뽑기권 */
  GACHA = 'GACHA',
  /** 찬란한 빛조각 */
  RADIANT_SHARD = 'RADIANT_SHARD',
  /** 희귀재료 */
  RARITY = 'RARITY',
  /** 바다 희귀재료 */
  SEA_RARITY = 'SEA_RARITY',
  /** 소모품 */
  SUPPLY = 'SUPPLY',
  /** 스킬 파우더 */
  SKILL_POWDER = 'SKILL_POWDER',
  /** 경험의 별사탕 */
  STAR_JELLY = 'STAR_JELLY',
  /** 잼스톤 전용 재료 */
  RESONANT_INGREDIENT = 'RESONANT_INGREDIENT',
  /** 마법사탕 전용 재료 */
  MAGIC_CANDY_INGREDIENT = 'MAGIC_CANDY_INGREDIENT',
  /** 토핑 조각 */
  TOPPING_PIECE = 'TOPPING_PIECE',
  /** 재료 */
  MATERIAL = 'MATERIAL',
  /** 생산품 */
  GOOD = 'GOOD',
  /** 설탕 결정 */
  SUGAR_CRYSTAL = 'SUGAR_CRYSTAL',
  /** 연합의 불꽃 */
  FLAME_OF_UNITY = 'FLAME_OF_UNITY',
  /** 빨리감기 태엽장치 */
  TIME_JUMPER = 'TIME_JUMPER',
}

/** 재화 */
export enum CurrencyEnum {
  CRYSTAL = 'crystal',
  RAINBOW_CUBE = 'rainbowCube',
  LIGHT_OF_RESOLUTION = 'lightOfResolution',
  MAGIC_COOKIE_CUTTER = 'magicCookieCutter',
  SPECIAL_COOKIE_CUTTER = 'specialCookieCutter',
  TREASURE_TICKET = 'treasureTicket',
  FROST_CRYSTAL = 'frostCrystal',
  SPEED_UP_1M = 'speedUp1m',
  SPEED_UP_10M = 'speedUp10m',
  SPEED_UP_30M = 'speedUp30m',
  SPEED_UP_1H = 'speedUp1h',
  SPEED_UP_12H = 'speedUp12h',
  AURORA_BRICK = 'auroraBrick',
  AURORA_COMPASS = 'auroraCompass',
  AURORA_PILLAR = 'auroraPillar',
  TIME_JUMPER = 'timeJumper',
  /** 경험의 별사탕 레벨 5 */
  STAR_JELLY_LV5 = 'starJellyLv5',
  /** 경험의 별사탕 레벨 6 */
  STAR_JELLY_LV6 = 'starJellyLv6',
  /** 경험의 별사탕 레벨 7 */
  STAR_JELLY_LV7 = 'starJellyLv7',
  /** 경험의 별사탕 레벨 8 */
  STAR_JELLY_LV8 = 'starJellyLv8',
  /** 코인 */
  COIN = 'coin',
  /** 스테미너젤리 */
  STAMINA_JELLY = 'staminaJelly',
  /** 찬란한 빛조각 */
  RADIANT_SHARD = 'radiantShard',
  /** 아마도 재배노움 */
  NEW_SUGAR_GNOME = 'newSugarGnome',
  /** 토핑 조각 */
  TOPPING_PIECE = 'toppingPiece',
  SEARING_KEY = 'searingKey',
  /** 아레나 티켓 */
  ARENA_TICKET = 'arenaTicket',
  /** 에픽 영혼의 조각 */
  EPIC_SOUL_ESSENCE = 'epicSoulEssence',
  /** 전설 영혼의 조각 */
  LEGENDARY_SOUL_ESSENCE = 'legendarySoulEssence',
  /** 디즈니 콜라보 뽑기 재화 */
  WISHING_STAR_CRYSTAL = 'wishingStarCrystal',
  /** BTS 콜라보 뽑기 재화 - 아미밤 */
  ARMY_BOMB = 'armyBomb',
  /** BTS 콜라보 미니게임 재화 - 콘서트 티켓 */
  CONCERT_TICKET = 'concertTicket',
  /** 소르베맛 쿠키 뽑기 재화 - 바람꽃 */
  WARMTH_IMBUED_WIND_FLOWERS = 'warmthImbuedWindFlowers',
}

/** 포지션 */
export enum Position {
  /** 전방 */
  FRONT = 'FRONT',
  /** 중앙 */
  MIDDLE = 'MIDDLE',
  /** 후방 */
  REAR = 'REAR',
}

export type PositionWithAll = Position | 'ALL'

/** 쿠키 타입 */

export enum CookieTypeEnum {
  /** 돌격형  */
  CHARGE = 'CHARGE',
  /** 방어형 */
  DEFENSE = 'DEFENSE',
  /** 마법형 */
  MAGIC = 'MAGIC',
  /** 사격형 */
  RANGED = 'RANGED',
  /** 침투형 */
  AMBUSH = 'AMBUSH',
  /** 회복형 */
  HEALING = 'HEALING',
  /** 폭발형 */
  BOMBER = 'BOMBER',
  /** 지원형 */
  SUPPORT = 'SUPPORT',
  /** BTS */
  BTS = 'BTS',
}

export type CookieTypeEnumWithAll = CookieTypeEnum | 'ALL'

export const COOKIE_TYPE_MAP: Record<CookieTypeEnum, ImageObject> = {
  [CookieTypeEnum.AMBUSH]: {
    name: {
      ko: '침투형',
      en: 'Ambush',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/fef28aec-6510-4f84-42d6-1f3ba3d20700/public',
  },
  [CookieTypeEnum.BOMBER]: {
    name: {
      ko: '폭발형',
      en: 'Bomber',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/95054666-ae56-4ccc-409a-a92ca2c97f00/public',
  },
  [CookieTypeEnum.CHARGE]: {
    name: {
      ko: '돌격형',
      en: 'Charge',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/aec653dd-6b7a-434e-9dd6-c90aa8604a00/public',
  },
  [CookieTypeEnum.DEFENSE]: {
    name: {
      ko: '방어형',
      en: 'Defense',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/04ea97ff-24fe-43d7-abe3-08f9ad40be00/public',
  },
  [CookieTypeEnum.HEALING]: {
    name: {
      ko: '회복형',
      en: 'Healing',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/9a3ae3e5-4daa-4251-6e76-8cae9365bf00/public',
  },
  [CookieTypeEnum.MAGIC]: {
    name: {
      ko: '마법형',
      en: 'Magic',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/708a995e-fc20-475c-1a3c-9ec7aa8d3600/public',
  },
  [CookieTypeEnum.RANGED]: {
    name: {
      ko: '사격형',
      en: 'Ranged',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/faff18d2-c3e4-46bb-2f9b-9518db973b00/public',
  },
  [CookieTypeEnum.SUPPORT]: {
    name: {
      ko: '지원형',
      en: 'Support',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/2317ec3d-2dd5-4c95-85fd-38f3a10e4500/public',
  },
  [CookieTypeEnum.BTS]: {
    name: {
      ko: 'BTS',
      en: 'BTS',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/bc134845-e552-4ebb-d55b-0d930ee70c00/public',
  },
}

export interface CookieType {
  cookieType: CookieTypeEnum
  name: LocalizedString
  image: string
}

export const COOKIE_TYPES: CookieType[] = Object.entries(COOKIE_TYPE_MAP).map(
  ([key, value]) => ({
    cookieType: key as CookieTypeEnum,
    ...value,
  }),
)

export interface Costume {
  grade: Grade
  name: LocalizedString
  image: {
    base: string
    card: string
    profile?: string
    illustration?: string
    animation?: string
    background?: {
      gacha: string
    }
  }
  /** 출시일 */
  releaseDatetime: string
  description?: LocalizedString
  effect?: LocalizedString
  trivia?: LocalizedString
}

export interface BaseCookie {
  id: string

  /** 이름 */
  name: LocalizedString
  /** 속성 */
  element?: ElementEnum
  elements?: ElementEnum[]
  /** 쿠키 타입 */
  cookieType: CookieTypeEnum
  /** 위치 */
  position: Position
  /** 출시일 */
  releaseDatetime: string
  /** 등급 */
  grade: Grade
  /** 대명사 */
  pronouns?: Pronouns
  /** 링크 */
  links?: string[]
  /** 영상 */
  videos?: {
    ko: string[]
    en: string[]
  }
  /** 성우 */
  voiceActor?: {
    /** 한국어 */
    [Locale.KO]: string
    /** 영어 */
    [Locale.EN]: string
    /** 일본어 */
    [Locale.JP]: string
    /** 대만어 */
    [Locale.TW]: string
    /** 태국어 */
    [Locale.TH]: string
    /** 독일어 */
    [Locale.DE]: string
    /** 프랑스어 */
    [Locale.FR]: string
  }
  /** 뽑았을 때 대사 */
  quote?: LocalizedString
  /** 평가 */
  evaluation?: LocalizedString
  /** 기본 정보
   * * 나무위키: 게임 설명 바로 아래 설명
   * * 팬덤위키: 맨 위 설명
   */
  info?: LocalizedString
  /** 게임 설명
   * * 나무위키: 맨 위 게임 설명
   * * 팬덤위키: Game Description
   */
  description?: LocalizedString
  /** 영혼석 설명 */
  soulstoneDescription?: LocalizedString
  /** 모티브 */
  characterInspiration?: LocalizedString
  /** 잡상식
   * * 나무위키: 기타
   * * 팬덤위키: Trivia
   */
  trivia?: LocalizedString
  /** 이명 */
  aliases?: {
    ko: string[]
    en: string[]
  }
  /** 뽑기 확률 */
  probability: number
  /** 이미지 */
  image: {
    /** Base Sprite, Stand */
    base?: string
    /** Base Sprite, Stand */
    base_star_2?: string
    /** Base Sprite, Stand */
    base_star_4?: string
    /** Base Sprite, Stand */
    base_star_6?: string
    /** Base2 */
    base2?: string
    /** Sprite */
    sprite?: string
    /** Head/Face Icon */
    icon: string
    /** Chracter Card */
    card: string
    /** Soulstone */
    soulstone: string
    /** Soulcore */
    soulcore?: string
    /** Illustration */
    illustration: string
    /** Illustration */
    illustration_star_2: string
    /** Illustration */
    illustration_star_4: string
    /** Illustration */
    illustration_star_6: string
    /** Gacha Animation */
    animation?: string
    /** Title Asset */
    titleAsset?: string
    /** Title Animation */
    titleAnimation?: string
    /** Profile Icon */
    profile?: string
    /** Background */
    background?: {
      /** Gacha Background */
      gacha: string
      /** Lobby Background */
      lobby?: string
    }
    /** April Fools */
    aprilFools?: {
      /** April Fools 2022 Sprite */
      sprite: string
      /** April Fools 2022 Character Card */
      card: string
    }
    /** April Fools */
    aprilFools23?: {
      /** April Fools 2023 Sprite */
      sprite: string
      /** April Fools 2023 Profile */
      profile: string
    }
    beta?: {
      illustration?: string
    }
    calligraphy?: {
      ko?: string
      en?: string
    }
    /** 실루엣 */
    silhouette?: string
  }
  /** 토핑 조합 목록 */
  toppings?: Topping[]
  /** MBTI */
  mbti?: MBTI
  /** MBTI 설명 */
  mbtiDescription?: LocalizedString
  /** 스킬 */
  skill: Skill
  /** 두번째 스킬 */
  skill2?: Omit<Skill, 'cooldown'> & { cooldown?: number }
  /** 마법사탕 */
  magicCandy?: {
    skill: Skill
    image: {
      lv0: string
      lv10: string
      lv20: string
    }
  }
  costumes?: Costume[]
  unreleased?: boolean
  playable?: boolean
  createdAt: string
  updatedAt: string
  awakened?: {
    name: LocalizedString
    quote: LocalizedString
    info: LocalizedString
    description: LocalizedString
    soulstoneDescription: LocalizedString
    skill: Skill
    image: {
      base?: string
      /** Base Sprite, Stand */
      base_star_2?: string
      /** Base Sprite, Stand */
      base_star_4?: string
      /** Base Sprite, Stand */
      base_star_6?: string
      icon?: string
      card?: string
      illustration?: string
      /** Illustration */
      illustration_star_2?: string
      /** Illustration */
      illustration_star_4?: string
      /** Illustration */
      illustration_star_6?: string
      animation?: string
      soulstone?: string
      profile?: string
      /** Background */
      background?: {
        /** Gacha Background */
        gacha: string
        /** Lobby Background */
        lobby?: string
      }
    }
  }
}

export interface Cookie extends BaseCookie {
  type: Type.COOKIE
}

export interface CookieStone extends BaseCookie {
  type: Type.COOKIE_STONE
}

export interface CookieStoneWithCount extends CookieStone {
  count: number
}

// export type MinimizeBaseCookie = Omit<
//   BaseCookie,
//   | 'pronouns'
//   | 'links'
//   | 'voiceActor'
//   | 'quote'
//   | 'evaluation'
//   | 'info'
//   | 'description'
//   | 'soulstoneDescription'
//   | 'characterInspiration'
//   | 'trivia'
//   | 'aliases'
//   | 'mbti'
//   | 'mbtiDescription'
//   | 'skill'
//   | 'skill2'
//   | 'costumes'
//   | 'playable'
// > & {
//   skill: {
//     name: LocalizedString
//     image: string
//     cooldown: number
//   }
//   magicCandy?: {
//     skill: {
//       name: LocalizedString
//       image: string
//       cooldown: number
//     }
//     image: {
//       lv0: string
//       lv10: string
//       lv20: string
//     }
//   }
// }

export type MiniBaseCookie = Pick<
  BaseCookie,
  | 'id'
  | 'name'
  | 'elements'
  | 'cookieType'
  | 'position'
  | 'releaseDatetime'
  | 'grade'
  | 'createdAt'
  | 'updatedAt'
  | 'unreleased'
> & {
  image: {
    /** Base Sprite, Stand */
    base?: string
    /** Head/Face Icon */
    icon: string
    /** Chracter Card */
    card: string
    /** Soulstone */
    soulstone: string
    /** Soulcore */
    soulcore?: string
    /** Illustration */
    illustration: string
    /** Gacha Animation */
    animation?: string
  }
  toppings?: {
    id: string
    name: LocalizedString
    image: string
  }[]
  skill: {
    name: LocalizedString
    image: string
    cooldown: number
  }
  skill2?: {
    name: LocalizedString
    image: string
    cooldown?: number
  }
  magicCandy?: {
    skill: {
      name: LocalizedString
      image: string
      cooldown: number
    }
    image: {
      lv0: string
      lv10: string
      lv20: string
    }
  }
}

export interface RichText {
  id: string
  name: LocalizedString
}

export interface CookieRichText extends RichText {
  image: {
    icon: string
  }
}
export interface TreasureRichText extends RichText {
  image: string
}

export interface ToppingRichText extends RichText {
  image: string
}

export interface SkillRichText extends Omit<RichText, 'id'> {
  image: string
}

export type MinimizeCookie = Omit<MiniBaseCookie, 'type'> & {
  type: Type.COOKIE
}

export type MinimizeCookieStone = Omit<MiniBaseCookie, 'type'> & {
  type: Type.COOKIE_STONE
}

export type MinimizeCookieWithEvalutation = MiniBaseCookie & {
  /** 평가 */
  evaluation?: LocalizedString
}

export interface Treasure {
  id: string
  createdAt: string
  updatedAt: string
  name: LocalizedString
  type?: TreasureType
  releaseDatetime: string
  grade: TreasureGrade
  links: string[]
  evaluation?: LocalizedString
  info?: LocalizedString
  description: LocalizedString
  effect?: LocalizedString
  probability: number
  image: string
  imageWithBackground?: string
  charge?: number
  cooldown?: number
  seriesTreasures: Treasure[]
}

export interface TreasureWithSeriesTreasureRefs
  extends Omit<Treasure, 'seriesTreasures'> {
  seriesTreasures: string[]
}

export type MinimizeTreasure = Omit<
  Treasure,
  | 'links'
  | 'evaluation'
  | 'info'
  | 'description'
  | 'effect'
  | 'probability'
  | 'charge'
  | 'cooldown'
  | 'seriesTreasures'
  | 'createdAt'
  | 'updatedAt'
>

export type GuestCookie = Omit<
  BaseCookie,
  | 'cookieType'
  | 'skill'
  | 'toppingCombination'
  | 'toppings'
  | 'position'
  | 'image'
  | 'evaluation'
  | 'soulstoneDescription'
  | 'characterInspiration'
  | 'aliases'
> & {
  image: {
    base: string
    icon: string
    card: string
  }
}

/** 보물 타입
 * * 액티브
 * * 패시브
 */
export enum TreasureType {
  /** 액티브 */
  ACTIVE = 'ACTIVE',
  /** 패시브 */
  PASSIVE = 'PASSIVE',
}

export type TreasureTypeWithAll = TreasureType | 'ALL'

export interface Topping {
  id: string
  name: LocalizedString
  effect: LocalizedString
  maxLevelEffect: LocalizedString
  description: LocalizedString
  tier: number
  equipBonus: {
    size: number
    effect: LocalizedString
  }[]
  image: string
}

export interface MinimizeCookieStoneWithCount extends MinimizeCookieStone {
  count: number
}

export type CookieGachaItem = MinimizeCookie | MinimizeCookieStoneWithCount

export type TreasureGachaItem = Treasure

export interface MinimizeTreasureWithCount extends MinimizeTreasure {
  probability: number
  count: number
}

export type CookieStoneInventory = MinimizeCookieStoneWithCount[]

export type TreasureGrade =
  | Grade.EPIC
  | Grade.RARE
  | Grade.COMMON
  | Grade.SPECIAL

export type GachaTreasureGrade = Grade.EPIC | Grade.RARE | Grade.COMMON

export type TreasureInventory = MinimizeTreasureWithCount[]

/** 쿠폰 등록 응답 타입 */
export enum ResponseTypeNew {
  UNKNOWN = 'UNKNOWN',
  /** 요청 성공 */
  RESPONSE_CODE_SUCCESS = 'RESPONSE_CODE_SUCCESS',
  /** 만료된 쿠폰 */
  RESPONSE_CODE_NOT_EVENT_TIME = 'RESPONSE_CODE_NOT_EVENT_TIME',
  /** 사용된 쿠폰 1 */
  RESPONSE_CODE_COUPON_CODE_ALREADY_USED = 'RESPONSE_CODE_COUPON_CODE_ALREADY_USED',
  /** 사용된 쿠폰 2 */
  // USED2 = 42201,
  /** 잘못된 계정명 */
  RESPONSE_CODE_ACCOUNT_NOT_FOUND = 'RESPONSE_CODE_ACCOUNT_NOT_FOUND',
  RESPONSE_CODE_INVALID_ACCOUNT_PARAM = 'RESPONSE_CODE_INVALID_ACCOUNT_PARAM',
  /** 잘못된 쿠폰 코드 */
  RESPONSE_CODE_COUPON_CODE_NOT_FOUND = 'RESPONSE_CODE_COUPON_CODE_NOT_FOUND',
  RESPONSE_CODE_NOT_TARGET_COUNTRY = 'RESPONSE_CODE_NOT_TARGET_COUNTRY',
  RESPONSE_CODE_COUPON_USAGE_ALREADY_MAX = 'RESPONSE_CODE_COUPON_USAGE_ALREADY_MAX',
  RESPONSE_CODE_CAPTCHA_TOKEN_VERIFY_FAIL = 'RESPONSE_CODE_CAPTCHA_TOKEN_VERIFY_FAIL',
  CAPTCHA_UNAUTHORIZED = 'CAPTCHA_UNAUTHORIZED',
}

export const COUPON_REGISTRATION_RESPONSE_MAP: Record<
  ResponseTypeNew,
  {
    alertMessage: string
  }
> = {
  [ResponseTypeNew.RESPONSE_CODE_SUCCESS]: {
    alertMessage:
      '상품이 정상적으로 지급되었습니다. 게임에 접속해서 확인해주세요.',
  },
  [ResponseTypeNew.RESPONSE_CODE_ACCOUNT_NOT_FOUND]: {
    alertMessage: 'DevPlay 계정을 다시 한번 확인해주세요.',
  },
  [ResponseTypeNew.RESPONSE_CODE_INVALID_ACCOUNT_PARAM]: {
    alertMessage: 'DevPlay 계정을 다시 한번 확인해주세요.',
  },
  [ResponseTypeNew.RESPONSE_CODE_COUPON_CODE_NOT_FOUND]: {
    alertMessage: '쿠폰 코드를 다시 한번 확인해주세요.',
  },
  [ResponseTypeNew.RESPONSE_CODE_NOT_TARGET_COUNTRY]: {
    alertMessage: '유효하지 않은 쿠폰 코드입니다.',
  },
  [ResponseTypeNew.RESPONSE_CODE_NOT_EVENT_TIME]: {
    alertMessage:
      '쿠폰 사용기간이 아닙니다. 유효기간을 다시 한번 확인해 주세요.',
  },
  [ResponseTypeNew.RESPONSE_CODE_COUPON_CODE_ALREADY_USED]: {
    alertMessage: '이미 사용된 쿠폰입니다.',
  },
  [ResponseTypeNew.RESPONSE_CODE_COUPON_USAGE_ALREADY_MAX]: {
    alertMessage: '사용할 수 있는 쿠폰 수를 초과하였습니다.',
  },
  [ResponseTypeNew.RESPONSE_CODE_CAPTCHA_TOKEN_VERIFY_FAIL]: {
    alertMessage:
      '서버에서 알 수 없는 응답이 발생하였습니다. 잠시후 다시 시도해주세요.',
  },

  [ResponseTypeNew.UNKNOWN]: {
    alertMessage:
      '서버에서 알 수 없는 응답이 발생하였습니다. 잠시후 다시 시도해주세요.',
  },
  [ResponseTypeNew.CAPTCHA_UNAUTHORIZED]: {
    alertMessage: 'CAPTCHA 인증이 진행 중입니다. 잠시만 기다려주세요.',
  },
}

export interface Currency {
  id: string
  createdAt: string
  updatedAt: string
  types: CurrencyType[]
  name: LocalizedString
  description?: LocalizedString
  evaluation?: LocalizedString
  image: string
}

export type MiniCurrency = Pick<Currency, 'id' | 'name' | 'image'>

export interface Coupon extends CouponCommon {}

export type BaseCoupon = Omit<Coupon, 'rewards'> & {
  rewards: {
    currency: {
      id: string
    }
    amount: number
  }[]
}

export interface FirebasePackage {
  id: string
  name: LocalizedString
  level: number
  image: string
  /** 원화 */
  price: number
  always: boolean
  /** @example 2023/01/21 12/03 */
  expirationDate: string
  rewards: {
    currency: Currency
    amount: number
  }[]

  createdAt: string
  updatedAt: string
}

export type FirebasePackageWithRelationKey = Omit<
  FirebasePackage,
  'rewards'
> & {
  rewards: {
    currencyId: string
    amount: number
  }[]
}

export enum Color {
  BROWN = 'BROWN',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  PURPLE = 'PURPLE',
}

export const COLOR_MAP: Record<Color, { label: LocalizedString }> = {
  [Color.BROWN]: {
    label: {
      ko: '갈색',
      en: 'Brown',
    },
  },
  [Color.BLUE]: {
    label: {
      ko: '파란색',
      en: 'Blue',
    },
  },
  [Color.GREEN]: {
    label: {
      ko: '녹색',
      en: 'Green',
    },
  },
  [Color.PURPLE]: {
    label: {
      ko: '보라색',
      en: 'Purple',
    },
  },
}

export interface StockColor {
  color: Color
  description: string
}

export interface Material {
  name: LocalizedString
  image: string
  color: Color
  link: string
}

export interface MaterialWithCount {
  material: Material
  count: number
}
export interface MaterialStyle {
  color: Color
  materialWithCountList: MaterialWithCount[]
  descriptionList: ReactNode[]
}

export interface Production {
  name: LocalizedString
  image: string
  color: Color
  link?: string
}

export interface ProductionWithCount {
  production: Production
  count: number
}

export interface ProductionStyle {
  name: LocalizedString
  description: ReactNode
}

export enum MBTICategory {
  /** 에너지 방향, 주의초점
   * * (+): E(Extraversion) 외향형
   * * (-): I(Introversion) 내향형
   */
  ENERGY_DIRECTION = 'ENERGY_DIRECTION',
  /** 인식기능(정보수집)
   * * (+): S(Sensing) 감각형
   * * (-): N(iNtuition) 직관형
   */
  SENSE = 'SENSE',
  /** 판단기능(판단, 결정)
   * * (+): T(Thinking) 사고형
   * * (-): F(Feeling) 감정형
   */
  JUDGEMENT = 'JUDGEMENT',
  /** 이행양식, 생활양식
   * * (+): J(Judging) 판단형 - 상황을 종료 하려고 합니다.
   * * (-): P(Perceiving) 인식형 - 상황을 개방적으로 유지하려고 노력합니다.
   */
  LIFESTYLE = 'LIFESTYLE',
}

export type MBTIScore = Record<MBTICategory, number>

export interface Answer {
  content: LocalizedString
  score: MBTIScore
}

export interface QuestionCard {
  question: {
    image: string
    title: LocalizedString
  }
  answers: Answer[]
}

export enum MBTI {
  INFP = 0,
  INFJ = 1,
  INTP = 2,
  INTJ = 3,
  ISFP = 4,
  ISFJ = 5,
  ISTP = 6,
  ISTJ = 7,
  ENFP = 8,
  ENFJ = 9,
  ENTP = 10,
  ENTJ = 11,
  ESFP = 12,
  ESFJ = 13,
  ESTP = 14,
  ESTJ = 15,
}

export interface ToppingWithCount {
  topping: ToppingRichText
  count: number
}

export interface CookieTierOnTable {
  tierIndex: number
  cookie: MinimizeCookieWithEvalutation
}

export interface CkkPackage {
  name: string
  level: string
  imageUrl: string
  price: number
  expirationDate: string
  timeDeal: boolean
  always: boolean
  addition: string

  reward: Record<CurrencyEnum, number>
}

export interface Article {
  id: string
  title: LocalizedString
  content: LocalizedString
  category: ArticleCategory
  author: Profile
  upvote?: string[]
  upvoteCount?: number
  recommend?: boolean
  downvote?: string[]
  createdAt: string
  updatedAt: string
  comments?: IComment[]
  readCount?: number
  featured?: boolean
  images?: string[]
  thumbnailImage?: string
}

export type BaseArticle = Omit<Article, 'author' | 'comments'> & {
  author: {
    uid: string
  }
  comments?: {
    id: string
  }[]
}

export type BaseArticleFormValues = Omit<BaseArticle, 'author'>

export enum TechnologyType {
  /** 왕국 연구 */
  KINGDOM = 'KINGDOM',
  /** 쿠키 연구 */
  COOKIE = 'COOKIE',
}

export interface Technology {
  id: string
  type: TechnologyType
  image?: string
  name: {
    ko: string
    en: string
  }
  description: {
    ko: string
    en: string
  }
  laboratoryLevel: number
  tier: number
  maxLevel: number
}

export interface Deck {
  cookies: MiniBaseCookie[]
  treasures: MinimizeTreasure[]
}

export enum LinkType {
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  NAVER_CAFE = 'NAVER_CAFE',
  NAVER_BLOG = 'NAVER_BLOG',
  NAMU_WIKI = 'NAMU_WIKI',
  FANDOM_WIKI = 'FANDOM_WIKI',
  KAKAO = 'KAKAO',
  INSTAGRAM = 'INSTAGRAM',
  DOPLE = 'DOPLE',
  UNKNOWN = 'UNKNOWN',
}

export interface ThemeDeck {
  title: LocalizedString
  links?: string[]
  description: LocalizedString
  decks: Deck[]
  author?: Profile
}

export enum Difficulty {
  NORMAL = 'NORMAL',
  DARK = 'DARK',
  MASTER = 'MASTER',
}

export const DIFFICULTY_MAP: Record<
  Difficulty,
  { name: LocalizedString; maxEpisode: number }
> = {
  [Difficulty.NORMAL]: { name: { ko: '일반', en: 'Normal' }, maxEpisode: 18 },
  [Difficulty.DARK]: { name: { ko: '어둠', en: 'Dark' }, maxEpisode: 18 },
  [Difficulty.MASTER]: { name: { ko: '마스터', en: 'Master' }, maxEpisode: 5 },
}

export const POSITION_MAP: Record<
  Position,
  ExtendedImageObject & { style: { background: string; border: string } }
> = {
  [Position.FRONT]: {
    name: {
      ko: '전방',
      en: 'Front',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/1e7fa644-7f99-42b2-a6fd-0d4886ec6a00/public',
    style: {
      background: '#434859',
      border: '2px solid rgba(255,255,255,0.5)',
    },
  },
  [Position.MIDDLE]: {
    name: {
      ko: '중앙',
      en: 'Middle',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/3217db77-f426-4374-9194-0077f2a49100/public',
    style: {
      background: '#764924',
      border: '2px solid rgba(255,255,255,0.5)',
    },
  },
  [Position.REAR]: {
    name: {
      ko: '후방',
      en: 'Rear',
    },
    image:
      'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/d264591c-744f-42f6-03d6-44a570fb5400/public',
    style: {
      background: '#8E4235',
      border: '2px solid rgba(255,255,255,0.5)',
    },
  },
}

export enum ContentEnum {
  /** 킹덤 아레나 */
  KINGDOM_ARENA = 'KINGDOM_ARENA',
  /** 월드 탐험 - 크리스피 월드 */
  WORLD_EXPLORATION = 'WORLD_EXPLORATION',
  /** 월드 탐험 - 비스트 이스트 */
  WORLD_EXPLORATION_BEAST_YEAST = 'WORLD_EXPLORATION_BEAST_YEAST',
  /** 비스트 레이드 - 눈물을 흘리는 바위산 */
  BEAST_RAID_THE_PEAK_OF_HOLLOW_TEARS = 'BEAST_RAID_THE_PEAK_OF_HOLLOW_TEARS',
  /** 토벌전 - 레드벨벳 케이크 드래곤 */
  GUILD_BATTLE_RED_VELVET_DRAGON = 'GUILD_BATTLE_RED_VELVET_DRAGON',
  /** 토벌전 - 운명의 대천사 */
  GUILD_BATTLE_AVATAR_OF_DESTINY = 'GUILD_BATTLE_AVATAR_OF_DESTINY',
  /** 토벌전 - 덩어리진 감초바다의 심연 */
  GUILD_BATTLE_LIVING_LICORICE = 'GUILD_BATTLE_LIVING_LICORICE',
  /** 수호의 성전 */
  COOKIE_ALLIANCE = 'COOKIE_ALLIANCE',
  // TODO: 값을 SUBLIME_CAKE_TOWER로 변경
  /** 초코 케이크 타워 */
  SUBLIME_CAKE_TOWER = 'CAKE_TOWER',
  /** 딸기 케이크 타워 */
  SUBLIME_CAKE_TOWER_ENDLESS_STRAWBERRY_CAKE_TOWER = 'SUBLIME_CAKE_TOWER_ENDLESS_STRAWBERRY_CAKE_TOWER',
  /** 대난투 */
  SUPER_MAYHEM = 'SUPER_MAYHEM',
}

export type ContentEnumWithAll = ContentEnum | 'ALL'

export type GuildBattleBoss =
  | ContentEnum.GUILD_BATTLE_RED_VELVET_DRAGON
  | ContentEnum.GUILD_BATTLE_AVATAR_OF_DESTINY
  | ContentEnum.GUILD_BATTLE_LIVING_LICORICE

export interface Content {
  content: ContentEnum
  name: LocalizedString
  image: {
    icon: string
  }
}

export const CONTENT_MAP: Record<ContentEnum, Content> = {
  [ContentEnum.KINGDOM_ARENA]: {
    content: ContentEnum.KINGDOM_ARENA,
    name: {
      ko: '아레나',
      en: 'Kingdom Arena',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/d1a56318-4635-496b-6b41-4218cc80a200/public',
    },
  },
  [ContentEnum.WORLD_EXPLORATION]: {
    content: ContentEnum.WORLD_EXPLORATION,
    name: {
      ko: '월드 탐험 - 크리스피 월드',
      en: 'World Exploration - Crispia',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/37ab3d04-0aa4-4177-a45c-73d2fb61a400/public',
    },
  },
  [ContentEnum.WORLD_EXPLORATION_BEAST_YEAST]: {
    content: ContentEnum.WORLD_EXPLORATION_BEAST_YEAST,
    name: {
      ko: '월드 탐험 - 비스트 이스트',
      en: 'World Exploration - Beast-Yeast',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/d1b2d0dc-3e90-4bde-cd7c-a3f044d75100/public',
    },
  },
  [ContentEnum.GUILD_BATTLE_RED_VELVET_DRAGON]: {
    content: ContentEnum.GUILD_BATTLE_RED_VELVET_DRAGON,
    name: {
      ko: '길드 토벌전 - 레드벨벳 케이크 드래곤',
      en: 'Guild Battle - Red Velvet Dragon',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/0cfcfea8-39ec-4fa8-6fbe-449c3cb6d400/public',
    },
  },
  [ContentEnum.GUILD_BATTLE_AVATAR_OF_DESTINY]: {
    content: ContentEnum.GUILD_BATTLE_AVATAR_OF_DESTINY,
    name: {
      ko: '길드 토벌전 - 운명의 대천사',
      en: 'Guild Battle - Avatar of Destiny',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/08d7eb8b-67ae-41ce-9b53-fb3f08d22200/public',
    },
  },
  [ContentEnum.GUILD_BATTLE_LIVING_LICORICE]: {
    content: ContentEnum.GUILD_BATTLE_LIVING_LICORICE,
    name: {
      ko: '길드 토벌전 - 덩어리진 감초바다의 심연',
      en: 'Guild Battle - Living Licorice Abyss',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/89238552-c416-43f5-6388-77eb5359bb00/public',
    },
  },
  [ContentEnum.COOKIE_ALLIANCE]: {
    content: ContentEnum.COOKIE_ALLIANCE,
    name: {
      ko: '수호의 성전',
      en: 'Cookie Alliance',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/61cc1cf3-4c14-4dff-55f3-54b782b96600/public',
    },
  },
  [ContentEnum.SUPER_MAYHEM]: {
    content: ContentEnum.SUPER_MAYHEM,
    name: {
      ko: '대난투',
      en: 'Super Mayhem',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/88937e8f-cc31-402b-0ae2-96925d84dd00/public',
    },
  },
  [ContentEnum.SUBLIME_CAKE_TOWER]: {
    content: ContentEnum.SUBLIME_CAKE_TOWER,
    name: {
      ko: '케이크 타워 - 결전의 초코 케이크타워',
      en: 'Sublime Cake Tower - Decadent Choco Cake Tower',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/e6c2d18c-e725-4fb4-07ec-6d1fae456400/public',
    },
  },
  [ContentEnum.SUBLIME_CAKE_TOWER_ENDLESS_STRAWBERRY_CAKE_TOWER]: {
    content: ContentEnum.SUBLIME_CAKE_TOWER_ENDLESS_STRAWBERRY_CAKE_TOWER,
    name: {
      ko: '케이크 타워 - 끝없는 딸기 케이크타워',
      en: 'Sublime Cake Tower - Endless Strawberry Cake Tower',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/e6c2d18c-e725-4fb4-07ec-6d1fae456400/public',
    },
  },
  [ContentEnum.BEAST_RAID_THE_PEAK_OF_HOLLOW_TEARS]: {
    content: ContentEnum.BEAST_RAID_THE_PEAK_OF_HOLLOW_TEARS,
    name: {
      ko: '비스트 레이드 - 눈물을 흘리는 바위산',
      en: 'Beast Raid - The Peak of Hollow Tears',
    },
    image: {
      icon: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/6da4697e-9241-47d2-3dd4-b6865c133f00/public',
    },
  },
}
export enum StatusEffectType {
  BUFF = 'BUFF',
  DEBUFF = 'DEBUFF',
}
export interface StatusEffect {
  type: StatusEffectType
  name: {
    en: string
    ko: string
  }
  effect: {
    en: string
    ko: string
  }
  image: string
}

export interface Opinion {
  id: string
  nickname: string
  content: string
  createdAt: string
}

export interface Tier<T> extends FirestoreCommon {
  data: T[][]
}

export interface BaseTier<T> extends FirestoreCommon {
  data: Record<number, T[]>
}

export interface BaseCookieTier extends BaseTier<string> {
  content: ContentEnumWithAll
}

export interface CookieTier extends Tier<BaseCookie> {
  content: ContentEnumWithAll
}

export interface BaseTreasureTier extends BaseTier<string> {}

export interface TreasureTier extends Tier<Treasure> {}

export enum PostType {
  ARTICLE = 'ARTICLE',
  DECK = 'DECK',
  CKT_PARTY = 'CKT_PARTY',
}

export interface IComment {
  id: string
  postType: CommunityPostType
  postId: string
  content: string
  createdAt: string
  updatedAt: string
  parentCommentId?: string
  author: Profile
  upvote?: string[]
  sticker?: {
    packId: string
    stickerId: string
  }
  image?: string
}

export type BaseComment = Omit<IComment, 'author'> & {
  author: {
    uid: string
  }
}

export type BaseDeckArticle = Omit<
  DeckArticle,
  'cookies' | 'treasures' | 'author' | 'comments' | 'decks'
> & {
  author: {
    uid: string
  }
  decks: {
    title?: LocalizedString
    description?: LocalizedString
    cookies: {
      id: string
      toppings: {
        id: string
      }[]
    }[]
    cookieDetails: string[]
    treasures: {
      id: string
    }[]
    treasureDetails: string[]
  }[]
  comments?: { id: string }[]
}

export type UserDeckFormValues = Omit<BaseDeckArticle, 'author'>

export interface FirebaseWorldExplorationDeckWithRelationKey {
  id: string
  title: LocalizedString
  description: LocalizedString
  links: string[]
  content: ContentEnum.WORLD_EXPLORATION
  difficulty: Difficulty
  episode: number
  stage: number
  cookies: {
    id: string
    toppings: {
      id: string
    }[]
  }[]
  treasures: {
    id: string
  }[]
}
