globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"97f4657a26df155239c83224331d1addb2913ba0"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://05602981f8f9572a28d3a83f8dd051bc@o1298855.ingest.us.sentry.io/4506247544242176',
  // Add optional integrations for additional features
  // integrations: [Sentry.replayIntegration()],
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,
  // Define how likely Replay events are sampled when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,
  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: process.env.NODE_ENV === 'development',
  // ignore all the Load Failed error on sentry
  ignoreErrors: [
    'Loading chunk',
    'Loading CSS chunk',
    'Loading script',
    'Script error.',
    'Load failed',
    'Failed to load resource',
  ],
})
