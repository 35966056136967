import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { create } from 'zustand';
import { Game } from '@/types/game';
const useGame = create<{
  game: Game;
  setGame: (game: Game) => void;
}>(set => ({
  game: undefined,
  setGame: game => set({
    game
  })
}));
interface Props {
  children: ReactNode;
}
function GameContext({
  children
}: Props) {
  const {
    setGame
  } = useGame();
  const {
    pathname
  } = useRouter();
  useEffect(() => {
    let nextGame = null;
    if (pathname.includes('ckk')) {
      nextGame = Game.CKK;
    }
    if (pathname.includes('cko')) {
      nextGame = Game.CKO;
    }
    if (pathname.includes('ckt')) {
      nextGame = Game.CKT;
    }
    if (pathname.includes('brixity')) {
      nextGame = Game.BRIXITY;
    }
    setGame(nextGame);
  }, [pathname, setGame]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
export { GameContext };
export default useGame;