import { ReactNode } from 'react';
import { Tag } from 'antd';
import reactStringReplace from 'react-string-replace';
import { MiniBaseCookie, LocalizedString, Position } from '@/types/ckk';
import { MenuTag, Reward } from '@/types/shared';
export const getStarLvFromCookieStone = (cookieStone: number): number | null => {
  if (cookieStone < getCookieStoneForStarLv(0)) return -1;
  if (cookieStone < getCookieStoneForStarLv(1)) return 0;
  if (cookieStone < getCookieStoneForStarLv(2)) return 1;
  if (cookieStone < getCookieStoneForStarLv(3)) return 2;
  if (cookieStone < getCookieStoneForStarLv(4)) return 3;
  if (cookieStone < getCookieStoneForStarLv(5)) return 4;
  if (cookieStone < getCookieStoneForStarLv(6)) return 5;
  if (cookieStone < getCookieStoneForStarLv(7)) return 6;
  if (cookieStone < getCookieStoneForStarLv(8)) return 7;
  if (cookieStone < getCookieStoneForStarLv(9)) return 8;
  if (cookieStone < getCookieStoneForStarLv(10)) return 9;
  return 10;
};
export const getLvFromTreasure = (treasure: number): number | null => {
  if (treasure === getTreasureForLv(0)) return 0;
  if (treasure === getTreasureForLv(1)) {
    return 1;
  }
  if (treasure >= getTreasureForLv(2) && treasure < getTreasureForLv(3)) {
    return 2;
  }
  if (treasure >= getTreasureForLv(3) && treasure < getTreasureForLv(4)) {
    return 3;
  }
  if (treasure >= getTreasureForLv(4) && treasure < getTreasureForLv(5)) {
    return 4;
  }
  if (treasure >= getTreasureForLv(5) && treasure < getTreasureForLv(6)) {
    return 5;
  }
  if (treasure >= getTreasureForLv(6) && treasure < getTreasureForLv(7)) {
    return 6;
  }
  if (treasure >= getTreasureForLv(7) && treasure < getTreasureForLv(8)) {
    return 7;
  }
  if (treasure >= getTreasureForLv(8) && treasure < getTreasureForLv(9)) {
    return 8;
  }
  if (treasure >= getTreasureForLv(9) && treasure < getTreasureForLv(10)) {
    return 9;
  }
  if (treasure >= getTreasureForLv(10) && treasure < getTreasureForLv(11)) {
    return 10;
  }
  if (treasure >= getTreasureForLv(11) && treasure < getTreasureForLv(12)) {
    return 11;
  }
  if (treasure === getTreasureForLv(12)) {
    return 12;
  }
};
export const getCookieStoneForStarLv = (level: number): number => {
  if (level === 0) {
    return 20;
  }
  if (level === 1) {
    return 40;
  }
  if (level === 2) {
    return 70;
  }
  if (level === 3) {
    return 120;
  }
  if (level === 4) {
    return 190;
  }
  if (level === 5) {
    return 290;
  }
  if (level === 6) {
    return 310;
  }
  if (level === 7) {
    return 340;
  }
  if (level === 8) {
    return 380;
  }
  if (level === 9) {
    return 430;
  }
  if (level === 10) {
    return 490;
  }
  return null;
};
export const getTreasureForLv = (level: number): number => {
  if (level === 0) {
    return 0;
  }
  if (level === 1) {
    return 1;
  }
  if (level === 2) {
    return 2;
  }
  if (level === 3) {
    return 4;
  }
  if (level === 4) {
    return 7;
  }
  if (level === 5) {
    return 11;
  }
  if (level === 6) {
    return 16;
  }
  if (level === 7) {
    return 22;
  }
  if (level === 8) {
    return 29;
  }
  if (level === 9) {
    return 37;
  }
  if (level === 10) {
    return 46;
  }
  if (level === 11) {
    return 56;
  }
  if (level === 12) {
    return 67;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNodeText = (node: any): string => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
  return '';
};
export const getTierNameByTierDigit = (tierDigit: number): 'S+' | 'S' | 'A' | 'B' | 'C' | 'D' | 'F' | '$' => {
  switch (tierDigit) {
    case 0:
      return 'S+';
    case 1:
      return 'S';
    case 2:
      return 'A';
    case 3:
      return 'B';
    case 4:
      return 'C';
    case 5:
      return 'D';
    case 6:
      return 'F';
    default:
      break;
  }
};
export const TIER_DIGIT_MAP: Record<number, {
  name: string;
  color: string;
}> = {
  0: {
    name: 'S+',
    color: 'rgb(255,127,127)'
  },
  1: {
    name: 'S',
    color: 'rgb(255,191,126)'
  },
  2: {
    name: 'A',
    color: 'rgb(255,223,126)'
  },
  3: {
    name: 'B',
    color: 'rgb(254,255,127)'
  },
  4: {
    name: 'C',
    color: 'rgb(191,255,127)'
  },
  5: {
    name: 'D',
    color: '#99c6de'
  },
  6: {
    name: 'F',
    color: '#8b8b8b'
  },
  7: {
    name: '$',
    color: '#c4c4c4'
  }
};
export const renderTag = (tag: MenuTag) => {
  switch (tag) {
    case MenuTag.NEW:
      return <Tag bordered={false} className="ckk !m-0 font-bold" color="green" style={{
        fontSize: '10px',
        verticalAlign: 'middle'
      }}>
          <span className="mr-1">🎉</span>NEW
        </Tag>;
    case MenuTag.HOT:
      return <Tag bordered={false} className="ckk !m-0 font-bold" color="red" style={{
        fontSize: '10px',
        verticalAlign: 'middle'
      }}>
          <span className="mr-1">🔥</span>HOT
        </Tag>;
    case MenuTag.UPDATE:
      return <Tag bordered={false} className="ckk !m-0 font-bold" color="gold" style={{
        fontSize: '10px',
        verticalAlign: 'middle'
      }}>
          <span className="mr-1">💡</span>UPDATE
        </Tag>;
    default:
      break;
  }
};

/** 쿠키 포지션 정렬 */
export const cookiePositionSorter = (a: MiniBaseCookie, b: MiniBaseCookie) => {
  const aPosition = a.position === Position.REAR ? 2 : a.position === Position.MIDDLE ? 1 : 0;
  const bPosition = b.position === Position.REAR ? 2 : b.position === Position.MIDDLE ? 1 : 0;
  return aPosition - bPosition;
};
export const getPositionedCookies = (cookies: MiniBaseCookie[]): {
  [Position.FRONT]: MiniBaseCookie[];
  [Position.MIDDLE]: MiniBaseCookie[];
  [Position.REAR]: MiniBaseCookie[];
} => {
  const sortedCookies = cookies.sort(cookiePositionSorter);
  const result = {
    [Position.FRONT]: [],
    [Position.MIDDLE]: [],
    [Position.REAR]: []
  };
  sortedCookies.forEach(cookie => {
    result[cookie.position].push(cookie);
  });
  if (result[Position.FRONT].length > 2) {
    result[Position.MIDDLE] = [...result[Position.FRONT].slice(2), ...result[Position.MIDDLE]];
    result[Position.FRONT] = result[Position.FRONT].slice(0, 2);
  }
  if (result[Position.MIDDLE].length > 2) {
    result[Position.REAR] = [...result[Position.MIDDLE].slice(2), ...result[Position.REAR]];
    result[Position.MIDDLE] = result[Position.MIDDLE].slice(0, 2);
  }
  if (result[Position.REAR].length > 2) {
    result[Position.MIDDLE] = [...result[Position.MIDDLE], ...result[Position.REAR].slice(0, result[Position.REAR].length - 2)];
    result[Position.REAR] = result[Position.REAR].slice(result[Position.REAR].length - 2);
  }
  if (result[Position.MIDDLE].length > 2) {
    result[Position.FRONT] = [...result[Position.FRONT], ...result[Position.MIDDLE].slice(0, result[Position.MIDDLE].length - 2)];
    result[Position.MIDDLE] = result[Position.MIDDLE].slice(result[Position.MIDDLE].length - 2);
  }
  return result;
};
export const getTotalCrystalFromReward = (rewards: Reward[]) => {
  const crystal = rewards.find(reward => reward.currency.name.ko === '크리스탈')?.amount ?? 0;
  const magicCookieCutter = rewards.find(reward => reward.currency.name.ko === '마법의 쿠키 커터')?.amount ?? 0;
  const specialCookieCutter = rewards.find(reward => reward.currency.name.ko === '특별한 쿠키 커터')?.amount ?? 0;
  const treasureTicket = rewards.find(reward => reward.currency.name.ko === '보물 뽑기권')?.amount ?? 0;
  return crystal + magicCookieCutter * 300 + specialCookieCutter * 300 + treasureTicket * 200;
};
type RichText = string | React.ReactNode[];
const LOCALES = ['ko', 'en'] as const;
export function convertRichText<T extends {
  name: LocalizedString;
}, P extends {
  name: LocalizedString;
}>({
  richText,
  items,
  targetConverter,
  render,
  regexList
}: {
  richText: RichText;
  items: T[];
  targetConverter?: (item: T) => P;
  render: (item: T, replacedName?: LocalizedString) => ReactNode;
  regexList?: [string, string];
}) {
  let targetItems: T[] | P[] = items;
  if (targetConverter) {
    targetItems = items.map(targetConverter);
  }
  targetItems.forEach((targetItem, index) => {
    const {
      name
    } = targetItem;
    const item = items[index];
    LOCALES.forEach(locale => {
      let regex = regexList ? new RegExp(regexList[0] + name[locale] + regexList[1], 'g') : name[locale];
      const 한국어_진_쿠키인_경우 = name[locale] === '진 쿠키';
      if (한국어_진_쿠키인_경우) {
        regex = new RegExp(/\s(진\s쿠키)/);
      }
      richText = reactStringReplace(richText, regex, matchResult => {
        if (regexList) {
          return render(item, {
            ko: matchResult,
            en: matchResult
          });
        }
        return item ? 한국어_진_쿠키인_경우 ? <> {render(item)}</> : render(item) : matchResult;
      });
    });
  });
  return richText;
}
export const getFilledValueSize = object => {
  let filledValueSize = 0;
  const keys = Object.keys(object);
  keys.forEach(key => {
    if (typeof object[key] === 'string') {
      if (object[key]) {
        filledValueSize += 1;
        return;
      }
    } else {
      Object.keys(object[key]).forEach(key2 => {
        if (!object[key][key2]) return;
        if (typeof object[key][key2] === 'string' && object[key][key2] !== '') {
          filledValueSize += 1;
          return;
        }
        Object.keys(object[key][key2]).forEach(key3 => {
          if (!object[key][key2][key3]) return;
          if (typeof object[key][key2][key3] === 'string') {
            if (object[key][key2][key3]) {
              filledValueSize += 1;
            }
          } else {
            Object.keys(object[key][key2][key3]).forEach(key4 => {
              if (typeof object[key][key2][key3][key4] === 'string') {
                if (object[key][key2][key3][key4]) {
                  filledValueSize += 1;
                }
              }
            });
          }
        });
      });
    }
  });
  return filledValueSize;
};