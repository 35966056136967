import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { faGear, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FloatButton, Row } from 'antd';
import { useUser, withUser } from 'next-firebase-auth';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { type OpenGraph } from 'next-seo/lib/types';
import { useInterval } from 'usehooks-ts';
import AnchorBottomAd from '@/components/adsense/AnchorBottomAd';
import MiddleContentAd from '@/components/adsense/MiddleContentAd';
import Logo from '@/components/ckk/Logo';
import Header from '@/components/layout/Header';
import StickyHeader from '@/components/StickyHeader';
import { 설탕노움_비밀창고_사이트_대표_이미지 } from '@/constants/ckk/image';
import { COOKIE_RUN_KINGDOM_MENUS } from '@/constants/ckk/menu';
import { COOKIE_RUN_TOWER_OF_ADVENTURE_MENUS } from '@/constants/ckt/menu';
import { Config, GAME_DOMAIN_CONFIG, SITE_CONFIG } from '@/constants/config';
import { 브릭시티_로고_이미지, 쿠키런_오븐브레이크_로고_이미지 } from '@/constants/image';
import { COMMON_MENUS } from '@/constants/menu';
import { Game } from '@/types/game';
import { getIsAdmin } from '@/utils/admin';
import { isBrowser } from '@/utils/env';
import { useIsWebview } from '@/utils/hooks/useIsWebview';
import useGame from '@/zustand/game';
const ResponsiveAd = dynamic(() => import('@/components/adsense/ResponsiveAd'), {
  ssr: false
});
const LayoutHeader = dynamic(() => import('@/components/ckk/LayoutHeader'), {
  ssr: true
});
const Footer = dynamic(() => import('@/components/layout/Footer'), {
  ssr: false
});
const DEFAULT_GAME = Game.CKK;
export interface LayoutProps extends Partial<Omit<Config, 'title' | 'applicationName' | 'description' | 'image'> & {
  title: string;
  applicationName: string;
  description: string;
  image: {
    appIcon?: string;
    main?: {
      url: string;
      alt: string;
      width: number;
      height: number;
    };
  };
}> {
  children: ReactNode;
  stickyHeader?: {
    component: ReactNode;
    breakpoint: number;
  };
  visibleAd?: {
    middle: boolean;
    anchor: boolean;
  };
  titleImage?: string;
  withLayoutTitle?: boolean;
  withLayoutDescription?: boolean;
  withScreenHeight?: boolean;
  game?: Game;
  openGraph?: OpenGraph;
  actions?: ReactNode;
}
function Layout({
  children,
  title: pageTitle = GAME_DOMAIN_CONFIG[DEFAULT_GAME].title.ko,
  applicationName = GAME_DOMAIN_CONFIG[DEFAULT_GAME].title.ko,
  description = GAME_DOMAIN_CONFIG[DEFAULT_GAME].title.ko,
  keywords = GAME_DOMAIN_CONFIG[DEFAULT_GAME].keywords,
  image = GAME_DOMAIN_CONFIG[DEFAULT_GAME].image,
  favicon = GAME_DOMAIN_CONFIG[DEFAULT_GAME].favicon,
  twitter = GAME_DOMAIN_CONFIG[DEFAULT_GAME].twitter,
  author,
  og = GAME_DOMAIN_CONFIG[DEFAULT_GAME].og,
  stickyHeader,
  visibleAd = {
    middle: true,
    anchor: true
  },
  withLayoutTitle = true,
  withLayoutDescription = true,
  withScreenHeight = false,
  openGraph,
  actions,
  titleImage
}: LayoutProps) {
  const {
    pathname,
    locale,
    push
  } = useRouter();
  const {
    t
  } = useTranslation('common');
  const user = useUser();
  const isAdmin = getIsAdmin(user?.firebaseUser);
  const isDetailPage = pathname.includes('/detail');
  const {
    game
  } = useGame();
  const [count, setCount] = useState(0);
  const [isWebview] = useIsWebview();
  useInterval(() => {
    setCount(count + 1);
  }, 30 * 1000);
  const isIndexPage = pathname === '/' || pathname === '/ckk';
  const baseTitle: string = game ? GAME_DOMAIN_CONFIG[game].title[locale] : SITE_CONFIG.title[locale];
  const title = !isIndexPage && pageTitle ? `${pageTitle} - ${baseTitle}` : baseTitle;
  const url = isBrowser ? window.location.href : '';
  const h1Tag = pageTitle ?? baseTitle;
  const gamePathname = game ? GAME_DOMAIN_CONFIG[game].pathname : '/';
  return <>
      <div className={`flex min-h-screen w-full flex-col items-center locale-${locale}`} id="layout" style={{
      background: `rgba(0, 0, 0, 0.15)`
    }}>
        <NextSeo description={openGraph?.description ?? description} openGraph={{
        type: og.type,
        title,
        siteName: applicationName[locale],
        description,
        url,
        locale,
        images: [image.main],
        ...openGraph
      }} title={title} twitter={{
        cardType: twitter.card,
        site: twitter.site,
        handle: twitter.creator
      }} data-sentry-element="NextSeo" data-sentry-source-file="Layout.tsx" />
        <Head data-sentry-element="Head" data-sentry-source-file="Layout.tsx">
          <meta content={keywords.join(',')} name="keywords" data-sentry-element="meta" data-sentry-source-file="Layout.tsx" />
          <meta content={author} name="author" data-sentry-element="meta" data-sentry-source-file="Layout.tsx" />
          <title>{title}</title>
          <link href={favicon.url} rel="shortcut icon" />
          <meta content={image.main.url} property="twitter:image" data-sentry-element="meta" data-sentry-source-file="Layout.tsx"></meta>
          <meta content={title} property="twitter:title" data-sentry-element="meta" data-sentry-source-file="Layout.tsx"></meta>
          <meta content={description} property="twitter:description" data-sentry-element="meta" data-sentry-source-file="Layout.tsx"></meta>
          <meta content={url} property="og:url" data-sentry-element="meta" data-sentry-source-file="Layout.tsx"></meta>
        </Head>
        <div className="relative mx-auto flex min-h-screen w-full max-w-[62rem] flex-col items-center" id="page-wrapper" style={{
        background: pathname === '/ckk/bts' || pathname === '/ckk/fun/mbti' ? '#efd8ff' : pathname === '/ckk/romance_kingdom' ? '#f8cade' : '#eee'
      }}>
          {!isWebview && <Header mainLogo={<Logo game={game} />} menuConfig={game === Game.CKK ? COOKIE_RUN_KINGDOM_MENUS({
          t,
          user: user.firebaseUser
        }) : game === Game.CKT ? COOKIE_RUN_TOWER_OF_ADVENTURE_MENUS({
          t,
          user: user.firebaseUser
        }) : COMMON_MENUS({
          t,
          user: user.firebaseUser
        })} />}
          <div className="!relative mx-auto flex min-h-screen w-full max-w-[62rem] flex-col items-center">
            <LayoutHeader actions={actions} description={description} game={game} h1Tag={h1Tag} title={pageTitle} titleImage={titleImage ?? game === Game.BRIXITY ? 브릭시티_로고_이미지 : game === Game.CKO ? 쿠키런_오븐브레이크_로고_이미지 : game === Game.CKT ? 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/26dfc8b0-952c-4f77-1e56-e95d23090d00/public' : undefined} withDescription={withLayoutDescription} withTitle={withLayoutTitle} data-sentry-element="LayoutHeader" data-sentry-source-file="Layout.tsx" />
            <div className={`w-full ${withLayoutTitle ? 'mt-2' : ''}`}></div>
            {stickyHeader && <StickyHeader breakpoint={stickyHeader.breakpoint}>
                {stickyHeader.component}
              </StickyHeader>}
            <main className="relative flex w-full flex-col flex-wrap items-center">
              {withScreenHeight ? <div className="relative w-full" style={{
              minHeight: `calc(100vh - ${(visibleAd.anchor ? 50 : 0) + 48}px)`
            }}>
                  {children}
                </div> : children}
              {visibleAd.middle && <Row align="middle" className="mt-4 w-full" gutter={[0, 16]} justify="center">
                  <ResponsiveAd key={`1_${count}`} index={0} />
                  <MiddleContentAd key={count} index={2} />
                  <ResponsiveAd key={`2_${count}`} index={1} />
                </Row>}
            </main>
          </div>
          {!isWebview && <Footer description={description} squareImageUrl={image.main.url === 설탕노움_비밀창고_사이트_대표_이미지 ? undefined : image.main.url} title={title} url={url} />}
          {!isWebview && visibleAd.anchor && <AnchorBottomAd key={count} />}
        </div>
      </div>
      <FloatButton.Group shape="square" style={{
      insetInlineEnd: 'unset',
      insetInlineStart: 24,
      bottom: 70
    }} data-sentry-element="unknown" data-sentry-source-file="Layout.tsx">
        {isAdmin && <FloatButton icon={<FontAwesomeIcon icon={faGear} />} type="primary" onClick={() => {
        const adminPathname = gamePathname + '/admin/cookie';
        push(adminPathname);
      }} />}
        {isAdmin && isDetailPage && <FloatButton icon={<FontAwesomeIcon icon={faEdit} />} type="primary" onClick={() => {
        push(window.location.pathname.replace(gamePathname, gamePathname + '/admin'));
      }} />}
      </FloatButton.Group>
      <FloatButton.Group shape="circle" style={{
      bottom: 70
    }} data-sentry-element="unknown" data-sentry-source-file="Layout.tsx">
        <FloatButton.BackTop data-sentry-element="unknown" data-sentry-source-file="Layout.tsx" />
      </FloatButton.Group>
    </>;
}
export default withUser<LayoutProps>()(Layout);